<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
        <div class="fullscreen-bg videoWrapper">
      <video autoplay loop autobuffer muted playsinline class="fullscreen-bg-vid">
        <source src="https://firebasestorage.googleapis.com/v0/b/tech-centrix-website.appspot.com/o/Introduction%20to%20viizzii%E2%84%A2.mp4?alt=media&token=25ab50e2-0f1e-4828-8e02-427317bc4d6a" type="video/mp4">
        <source src="movie.ogg" type="video/ogg">
        Your browser does not support the video tag.
      </video>
      <div class="overlay-clear">
        <v-container black--text>
          <div class="display-3">
            <span class="thin">Syndicate</span>
          </div><br />
          <div class="display-4">
            <span class="thick">Sales</span>
          </div>
        </v-container>
      </div>
    </div>
    <div>
    <div class="white">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="display-2 mb-1">SEND SOMETHING NEW WITH VIIZZII™</div>
            <div>
Express your feelings–your way–with a viizzii™ vase!

LED lights display your custom messages on a modern vase to show your loved one exactly how you feel. Showcase a beautiful bouquet, and your thoughts, with this uniquely personal experience.</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Largest U.S. distributor of floral hard goods</div>
            
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Licensed TechCentrix technology; used to create its own trademarked vase, the vizzii™</div>
            
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Recipient of International Award for Innovation showcasing the viizzii™</div>
            
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Multi-touch, Real-time Data Collection</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Bluetooth-capable messaging</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

   </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Case Studies',
          disabled: true,
          to: ''
        },
        {
          text: 'Syndicate Sales',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>